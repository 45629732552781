import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Arrow } from '../../../assets/img/arrow.svg';
import styles from './styles.module.scss';

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.navigation}>
      <li className={styles.navigation__item}>
        <NavLink exact to="/" className={styles.navigation__link}>
          {t('navigation.first')}
          <Arrow className={styles.navigation__arrow} fill="#F9BE5C" />
        </NavLink>
      </li>
      <li className={styles.navigation__item}>
        <a href="http://bergregions.pl/" className={styles.navigation__link}>
          {t('navigation.second')}
          <Arrow className={styles.navigation__arrow} fill="#F9BE5C" />
        </a>
      </li>
    </ul>
  );
};

export default Navigation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './video.module.scss';
import ButtonMain from '../button/buttonMain';

const Video = () => {
  const { t } = useTranslation();

  const handleButton = (e) => {
    e.preventDefault();
    window.location.href = 'http://bergregions.pl/';
  };

  return (
    <div className={styles.video}>
      <h2 className={styles.video__title}>{t('video.title')}</h2>
      <p className={styles.video__paragraph}>{t('video.paragraph')}</p>
      <div className={styles.video__box}>
        <iframe
          className={styles.video__iframe}
          src="https://player.vimeo.com/video/561501207"
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
          title="movie"
        />
      </div>
      <ButtonMain onPress={handleButton}>Ticket</ButtonMain>
    </div>
  );
};

export default Video;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import GlobalStyle from 'theme/GlobalStyle';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import LandingPage from './landingPage/landing';

const App = () => (
  <>
    <GlobalStyle />
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </>
);

export default App;

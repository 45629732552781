import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';
import Logo from '../../assets/img/logo.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__box}>
          <img className={styles.footer__logo} src={Logo} alt="Logo" />
          <p className={styles.footer__name}>{t('footer.address.name')}</p>
          <p className={styles.footer__address}>{t('footer.address.street')}</p>
          <p className={styles.footer__address}>{t('footer.address.city')}</p>
          <p className={styles.footer__address}>{t('footer.address.country')}</p>
        </div>
      </div>
      <p className={styles.footer__copyright}>{t('footer.copyright')}</p>
    </div>
  );
};

export default Footer;

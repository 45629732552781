import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  outline-style: none;
  width: 225px;
  height: 45px;
  border: 1px solid #e6ae30;
  border-radius: 2px;
  background: #e7b258;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }
`;

const ButtonMain = ({ children, onPress, bgColor }) => (
  <Button type="button" onClick={onPress} style={{ backgroundColor: bgColor }}>
    {children}
  </Button>
);

export default ButtonMain;

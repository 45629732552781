import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './landing.module.scss';
import { ReactComponent as Arrow } from '../../assets/img/arrow.svg';
import Video from '../../components/video/video';

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={styles.landing}>
        <img
          className={styles.landing__background}
          src={`${process.env.PUBLIC_URL}/assets/img/photo_main.jpg`}
          alt="Tło"
        />
        <h2 className={styles.landing__title}>{t('landing.greeting')}</h2>
        <p className={styles.landing__paragraph}>{t('landing.paragraph')}</p>
        <div className={styles.landing__arrowBox}>
          <Arrow className={styles.landing__arrow} fill="#FFFFFF" width={40} height={20} />
        </div>
      </section>
      <section
        style={{
          background: `url('${process.env.PUBLIC_URL}/assets/img/background_section.jpg')`,
          backgroundSize: 'cover',
        }}
      >
        <Video />
      </section>
    </>
  );
};

export default LandingPage;

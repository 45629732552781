import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './header.module.scss';
import Logo from '../../assets/img/logo.svg';
import Navigation from './components/navigation';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.header__container}>
      <NavLink exact to="/">
        <img src={Logo} alt="Portal Regionalny" width={83} height={83} />
      </NavLink>
      <Navigation />
    </div>
  </header>
);

export default Header;
